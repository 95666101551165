import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ArrowPathIcon, CloudArrowUpIcon, ScaleIcon, LockClosedIcon, UserIcon } from '@heroicons/react/24/outline';

const features = [
  {
    name: 'ERP a firemní zdroje',
    description:
      'Efektivní nástroje pro plánování a správu Vašich firemních zdrojů, které zjednodušují procesy a umožňují růst.',
    icon: ArrowPathIcon,
  },
  {
    name: 'CRM a zákaznické vztahy',
    description:
      'Správa zákazníků a objednávek v jediném systému, který Vám poskytne hlubší vhled a pomůže zlepšit zákaznickou zkušenost.',
    icon: UserIcon,
  },
  {
    name: 'CMS a obsahové systémy',
    description:
      'Jednoduché a intuitivní systémy pro správu obsahu, které Vám umožní plnou kontrolu nad Vaším digitálním obsahem.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Datově orientované aplikace',
    description:
      'Aplikace, které pracují s daty, jako je správa členství, rezervací, dokumentů nebo aktiv – navržené pro efektivitu a flexibilitu.',
    icon: ScaleIcon,
  },
];

export default function GoodFor() {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out', once: true });
  }, []);

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div id='goodFor' className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-indigo-600">Do detailu. Do posledního pixelu.</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Aplikace, které se dokonale přizpůsobí Vašim potřebám.
          </p>
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">
            Specializujeme se na aplikace, které zjednodušují Vaše podnikání – od ERP a CRM systémů až po správu obsahu a členství. S námi získáte efektivní řešení, které roste společně s Vámi. Zajímá Vás více? Rádi si s Vámi popovídáme online.
          </p> */}
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature, index) => (
              <div
                key={feature.name}
                className="relative pl-16"
                data-aos="fade-up"
                data-aos-delay={`${index * 100}`} // Postupné zpoždění pro každou položku
              >
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
