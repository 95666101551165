import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Video() {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out', once: true });
  }, []);

  return (
    <div className="bg-gray-50 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div id='video' className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-indigo-600">Jednoduše pro Vás.</h2>
          <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Aplikace na míru. Intuitivní design. Přesně podle Vašich představ.
          </p>
        </div>
        
        {/* Video section */}
        <div className="mx-auto mt-2 max-w-2xl sm:mt-10 lg:mt-14 lg:max-w-4xl">
          <div 
            className="relative flex items-center justify-center overflow-hidden"
            data-aos="fade-up"
          >
            <video
              className="rounded-md shadow-lg w-full h-auto"
              autoPlay
              loop
              muted
              playsInline
              preload='auto'
            >
              <source src={`${process.env.PUBLIC_URL}/app_video4.mov`} type="video/mp4" />
              Váš prohlížeč nepodporuje přehrávání videa.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}
