'use client'

import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-scroll'

const navigation = [
  { name: 'Jak probíhá spolupráce', href: '#timeline' },
  { name: 'Ceník', href: '#pricing' },
  { name: 'Typy aplikací', href: '#goodFor' },
  { name: 'Poptávka', href: '#demand' },
  { name: 'Konzultace zdarma', href: 'https://cal.com/pixeldev/30min', target: '_blank' },
]

export default function Top() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  // Handle menu close on link click
  const handleMenuClose = () => {
    setMobileMenuOpen(false)
  }

  return (
    <div id='home' className="bg-gray-50">
      <header className="absolute inset-x-0 top-0 z-50 bg-white shadow-sm">
        <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <a href="#home" className="-m-1.5 p-1.5 text-2xl font-bold tracking-tight text-indigo-600 sm:text-3xl">
              PixelDev
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600 transition">
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#home" className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-indigo-600">
                PixelDev
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Zavřít menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={handleMenuClose} // Close menu on link click
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 transition"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-20 lg:px-0">
        <div className="bg-white max-w-full py-16 lg:py-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            {/* Left Text Section */}
            <div className="text-left lg:px-20">
              <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:text-7xl">
                <span className="text-indigo-600">Webové aplikace na míru.</span> Cenově dostupné jako nikdy předtím.
              </h1>
                <p className="mt-6 text-xl text-gray-700 leading-8">
                  Přeměňte složité procesy na efektivní řešení s webovou aplikací na míru. Cenově dostupné, flexibilní a navržené tak, aby rostly s Vaším podnikáním.
                </p>
              <div className="mt-10 flex items-center gap-x-6">
                <Link
                  to="demand"
                  smooth={true}
                  duration={500}
                  style={{ cursor: 'pointer' }}
                  className="rounded-md bg-indigo-600 px-4 py-2 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition"
                >
                  Nezávazná poptávka
                </Link>
                <a href="https://cal.com/pixeldev/30min" className="text-lg font-semibold leading-6 text-indigo-600 hover:text-indigo-500 transition" target="_blank" style={{ cursor: 'pointer' }}>
                  Konzultace zdarma <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>

            {/* Right Image Section */}
            <div className="relative mt-10 lg:mt-0">
              <div className="overflow-hidden">
                <img
                  alt="App screenshot"
                  src={`${process.env.PUBLIC_URL}/web-app.png`}
                  className="rounded-md shadow-lg w-[190%] max-w-none transform translate-x-[10%]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
