export default function Pricing() {
  return (
    <div className="bg-gray-50 py-16 lg:py-28">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div id="pricing" className="text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Cenově dostupná řešení na míru. Rychlá, flexibilní a spolehlivá.
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Vyberte si plán, který nejlépe vyhovuje Vašim potřebám. Naše flexibilní cenové možnosti zajišťují, že platíte jen za to, co skutečně potřebujete.
          </p>
        </div>

        <div className="mt-12 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {/* Malá aplikace */}
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-900">Malá firemní aplikace</h3>
            <p className="mt-4 text-gray-600">Ideální pro malé týmy, úzce zacílené projekty a samostatné aplikace.</p>
            <p className="mt-6 text-4xl font-bold text-gray-900"><span className="text-lg font-normal">Od</span> 60 000 Kč</p>
            {/* <p className="mt-2 text-gray-600">Doba doručení: 1-2 týdny</p> */}
            <ul className="mt-6 space-y-4 text-gray-700">
              <li>✔ Webová aplikace na míru</li>
              <li>✔ Prvotřídní zabezpečení</li>
              <li>✔ Přístup ke zdrojovému kódu a datům</li>
              <li>✔ Až 2 uživatelské role (např. admin, manažer)</li>
            </ul>
            <a href="#demand">
              <button className="mt-8 w-full rounded-md bg-white px-4 py-2 text-indigo-600 font-semibold border border-indigo-600 hover:bg-indigo-50">
                Poptat řešení
              </button>
            </a>
          </div>

          {/* Střední aplikace */}
          <div className="relative bg-white p-8 rounded-lg shadow-md">
            <div className="absolute -top-3 right-3 rounded-full bg-indigo-100 px-3 py-1 text-sm text-indigo-700 font-semibold">Nejpopulárnější</div>
            <h3 className="text-lg font-semibold text-gray-900">Střední firemní aplikace</h3>
            <p className="mt-4 text-gray-600">Celopodnikové aplikace pro menší firmy vyžadující více rolí a propojení.</p>
            <p className="mt-6 text-4xl font-bold text-gray-900"><span className="text-lg font-normal">Od</span> 120 000 Kč</p>
            {/* <p className="mt-2 text-gray-600">Doba doručení: 2-3 týdny</p> */}
            <ul className="mt-6 space-y-4 text-gray-700">
              <li>✔ Webová aplikace na míru</li>
              <li>✔ Prvotřídní zabezpečení</li>
              <li>✔ Přístup ke zdrojovému kódu a datům</li>
              <li>✔ Až 4 uživatelské role (např. admin, obchodní zástupce, zákazník, skladník)</li>
              <li>✔ Jednoduchá externí propojení</li>
            </ul>
            <a href="#demand">
              <button className="mt-8 w-full rounded-md bg-indigo-600 px-4 py-2 text-white font-semibold hover:bg-indigo-500">
                Poptat řešení
              </button>
            </a>
          </div>

          {/* Velká aplikace */}
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-900">Velká firemní aplikace</h3>
            <p className="mt-4 text-gray-600">Celopodnikové aplikace pro středně velké firmy s širokou funkcionalitou a rolemi.</p>
            <p className="mt-6 text-4xl font-bold text-gray-900"><span className="text-lg font-normal">Od</span> 250 000 Kč</p>
            {/* <p className="mt-2 text-gray-600">Doba doručení: 4-5 týdnů</p> */}
            <ul className="mt-6 space-y-4 text-gray-700">
              <li>✔ Webová aplikace na míru</li>
              <li>✔ Prvotřídní zabezpečení</li>
              <li>✔ Přístup ke zdrojovému kódu a datům</li>
              <li>✔ Neomezený počet uživatelských rolí</li>
              <li>✔ Neomezený počet externích propojení</li>
              <li>✔ Neomezený počet iterací</li>
              <li>✔ Přednostní zákaznická podpora</li>
            </ul>
            <a href="#demand">
              <button className="mt-8 w-full rounded-md bg-white px-4 py-2 text-indigo-600 font-semibold border border-indigo-600 hover:bg-indigo-50">
                Poptat řešení
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
