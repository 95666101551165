import logo from './logo.svg';
import './App.css';
import Top from './Top';
import PocketPage from './PocketPage';
import AboutUs from './AboutUs';
import { DivideIcon } from '@heroicons/react/24/outline';
import Pricing from './Pricing';
import TwoXtwoDatagrid from './TwoXtwoDatagrid';
import Timeline from './Timeline';
import GoodFor from './GoodFor';
import ContactForm from './ContactForm';
import Footer from './Footer';
import Video from './Video';

function App() {
  return (
    <div>
      <Top />
      <TwoXtwoDatagrid />
      <Video />
      <Timeline />
      <GoodFor />
      <Pricing />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
