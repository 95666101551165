import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Timeline() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-gray-50 py-16 lg:py-28">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div id='timeline' className="text-center mb-12">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Na každém pixelu nám záleží.</h2>
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Efektivní spolupráce.
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Náš proces je navržen tak, abychom Vás co nejlépe pochopili a Vaše cesta k aplikaci byla co nejplynulejší.
          </p>
        </div>

        {/* Timeline */}
        <div className="relative">
          {/* Timeline line */}
          <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-indigo-300"></div>

          <div className="space-y-12">
            {/* Step 1 */}
            <div className="relative flex items-center" data-aos="fade-up">
              <div className="w-1/2 flex justify-end pr-8">
                <div className="text-right max-w-md">
                  <h3 className="text-2xl font-bold text-gray-900">Pochopení Vašich potřeb</h3>
                  <p className="mt-2 text-base text-gray-600">
                    První krok je o důkladném pochopení Vaší firmy, procesů a cílů. Nasloucháme, abychom Vám mohli nabídnout řešení, které bude plně odpovídat Vašim jedinečným potřebám.
                  </p>
                </div>
              </div>
              <div className="flex-shrink-0">
                <div className="relative w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center">
                  <span className="text-white text-lg font-bold">1</span>
                </div>
              </div>
              <div className="w-1/2"></div>
            </div>

            {/* Step 2 */}
            <div className="relative flex items-center" data-aos="fade-up">
              <div className="w-1/2"></div>
              <div className="flex-shrink-0">
                <div className="relative w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center">
                  <span className="text-white text-lg font-bold">2</span>
                </div>
              </div>
              <div className="w-1/2 flex justify-start pl-8">
                <div className="text-left max-w-md">
                  <h3 className="text-2xl font-bold text-gray-900">Návrh řešení na míru</h3>
                  <p className="mt-2 text-base text-gray-600">
                    Na základě Vašich potřeb navrhneme demo verzi aplikace, kterou Vám rychle dodáme. Společně projdeme funkce a vyladíme každý detail tak, aby aplikace dokonale odpovídala Vašim předstaVám.
                  </p>
                </div>
              </div>
            </div>

            {/* Step 3 */}
            <div className="relative flex items-center" data-aos="fade-up">
              <div className="w-1/2 flex justify-end pr-8">
                <div className="text-right max-w-md">
                  <h3 className="text-2xl font-bold text-gray-900">Vývoj finální aplikace</h3>
                  <p className="mt-2 text-base text-gray-600">
                    Jakmile dojde ke schválení demo verze, pustíme se do vývoje finální aplikace. Pravidelně komunikujeme, abychom zajistili, že výsledek splní všechna Vaše očekávání.
                  </p>
                </div>
              </div>
              <div className="flex-shrink-0">
                <div className="relative w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center">
                  <span className="text-white text-lg font-bold">3</span>
                </div>
              </div>
              <div className="w-1/2"></div>
            </div>

            {/* Step 4 */}
            <div className="relative flex items-center" data-aos="fade-up">
              <div className="w-1/2"></div>
              <div className="flex-shrink-0">
                <div className="relative w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center">
                  <span className="text-white text-lg font-bold">4</span>
                </div>
              </div>
              <div className="w-1/2 flex justify-start pl-8">
                <div className="text-left max-w-md">
                  <h3 className="text-2xl font-bold text-gray-900">Předání a zajištění provozu</h3>
                  <p className="mt-2 text-base text-gray-600">
                    Po dokončení vývoje Vám předáme hotovou aplikaci a zajistíme její bezproblémový provoz. Budeme s Vámi i nadále spolupracovat, aby vše běželo tak, jak má.
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}
